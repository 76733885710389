
import * as vercelRuntime$RAhacjKFlK from '/vercel/path0/node_modules/.pnpm/@nuxt+image-edge@1.0.0-27840416.dc1ed65_rollup@2.79.1/node_modules/@nuxt/image-edge/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 56,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "xxs": 20
  },
  "presets": {
    "selectItem": {
      "modifiers": {
        "width": 20,
        "format": "webp"
      }
    },
    "card": {
      "modifiers": {
        "width": 56,
        "format": "webp"
      }
    }
  },
  "provider": "vercel",
  "domains": [
    "s3.amazonaws.com",
    "clicampo-prod-clicampo-braincolis-images.s3.amazonaws.com"
  ],
  "alias": {}
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$RAhacjKFlK, defaults: {"modifiers":{"format":"webp"}} }
}
        