const roleMapping: Record<string, string> = {
    impersonate: 'clicampo:shoppingnon:admin',
}

const isAuthorized = (route: any, roles: any) => {
    const pageRole = Object.keys(roleMapping)
        .find(page => route.path.includes(page))
    if (pageRole === undefined) {
        return true
    }
    return roles?.[0]?.role?.includes(roleMapping[pageRole])
}

export default defineNuxtRouteMiddleware(async (_to, _from) => {
    if (_to.path === '/login' || _to.path === '/logout') { return }
    const user = useSupabaseUser()
    const supabase = useSupabaseClient<any>()

    if (!user.value) {
        return navigateTo('/login')
    }
    const { data } = await supabase
        .from('auth_user')
        .select('role')
        .eq('email', user.value.email)

    if (!(data && data?.[0]?.role?.includes('clicampo'))) {
        return navigateTo('/logout')
    }

    const userIsAuthorizedOnRoute = isAuthorized(_to, data)
    if (!userIsAuthorizedOnRoute) {
        return navigateTo('/')
    }
})
