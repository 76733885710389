import { default as _47vercel_47path0_47pages_47404_46vueMeta } from "/vercel/path0/pages/404.vue?macro=true";
import { default as _47vercel_47path0_47pages_47impersonate_46vueMeta } from "/vercel/path0/pages/impersonate.vue?macro=true";
import { default as _47vercel_47path0_47pages_47index_46vueMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _47vercel_47path0_47pages_47login_46vueMeta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as _47vercel_47path0_47pages_47logout_46vueMeta } from "/vercel/path0/pages/logout.vue?macro=true";
import { default as _47vercel_47path0_47pages_47pg_46vueMeta } from "/vercel/path0/pages/pg.vue?macro=true";
import { default as _47vercel_47path0_47pages_47quotations_46vueMeta } from "/vercel/path0/pages/quotations.vue?macro=true";
export default [
  {
    name: _47vercel_47path0_47pages_47404_46vueMeta?.name ?? "404",
    path: _47vercel_47path0_47pages_47404_46vueMeta?.path ?? "/404",
    file: "/vercel/path0/pages/404.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47404_46vueMeta,
    alias: _47vercel_47path0_47pages_47404_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47404_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47impersonate_46vueMeta?.name ?? "impersonate",
    path: _47vercel_47path0_47pages_47impersonate_46vueMeta?.path ?? "/impersonate",
    file: "/vercel/path0/pages/impersonate.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47impersonate_46vueMeta,
    alias: _47vercel_47path0_47pages_47impersonate_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47impersonate_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/impersonate.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47index_46vueMeta?.name ?? "index",
    path: _47vercel_47path0_47pages_47index_46vueMeta?.path ?? "/",
    file: "/vercel/path0/pages/index.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47index_46vueMeta,
    alias: _47vercel_47path0_47pages_47index_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47login_46vueMeta?.name ?? "login",
    path: _47vercel_47path0_47pages_47login_46vueMeta?.path ?? "/login",
    file: "/vercel/path0/pages/login.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47login_46vueMeta,
    alias: _47vercel_47path0_47pages_47login_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47login_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47logout_46vueMeta?.name ?? "logout",
    path: _47vercel_47path0_47pages_47logout_46vueMeta?.path ?? "/logout",
    file: "/vercel/path0/pages/logout.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47logout_46vueMeta,
    alias: _47vercel_47path0_47pages_47logout_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47logout_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47pg_46vueMeta?.name ?? "pg",
    path: _47vercel_47path0_47pages_47pg_46vueMeta?.path ?? "/pg",
    file: "/vercel/path0/pages/pg.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47pg_46vueMeta,
    alias: _47vercel_47path0_47pages_47pg_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47pg_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/pg.vue").then(m => m.default || m)
  },
  {
    name: _47vercel_47path0_47pages_47quotations_46vueMeta?.name ?? "quotations",
    path: _47vercel_47path0_47pages_47quotations_46vueMeta?.path ?? "/quotations",
    file: "/vercel/path0/pages/quotations.vue",
    children: [],
    meta: _47vercel_47path0_47pages_47quotations_46vueMeta,
    alias: _47vercel_47path0_47pages_47quotations_46vueMeta?.alias || [],
    redirect: _47vercel_47path0_47pages_47quotations_46vueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/quotations.vue").then(m => m.default || m)
  }
]