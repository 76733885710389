import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

export default defineNuxtPlugin((nuxtApp) => {
    const { vueApp } = nuxtApp
    const { environment, sentryDSN, appVersion } = useRuntimeConfig().public

    Sentry.init({
        app: [vueApp],
        dsn: sentryDSN,
        dist: appVersion,
        release: appVersion,
        enabled: !process.dev,
        environment: environment,
        tracesSampleRate: 1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new Sentry.Replay(),
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router),
            }),
        ],
        beforeSend(event, hint) {
            if (event.exception) {
                console.error(
                    `[SentryClient]: (${hint.originalException})`,
                    { event, hint },
                )
            }
            return event
        },
    })

    if (!process.dev) {
        vueApp.mixin(Sentry.createTracingMixins({
            trackComponents: true,
            timeout: 2000,
            hooks: ['activate', 'mount', 'update'],
        }))
        Sentry.attachErrorHandler(vueApp, {
            logErrors: false,
            attachProps: true,
            normalizeDepth: 10,
            trackComponents: true,
            timeout: 2000,
            hooks: ['activate', 'mount', 'update'],
        })
    }

    return {
        provide: {
            sentrySetContext: Sentry.setContext,
            sentrySetUser: Sentry.setUser,
            sentrySetTag: Sentry.setTag,
            sentryAddBreadcrumb: Sentry.addBreadcrumb,
            sentryCaptureException: Sentry.captureException,
        },
    }
})
