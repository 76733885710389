export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"mobile-web-app-capable","name":"mobile-web-app-capable","content":"yes"},{"hid":"apple-mobile-web-app-status-bar-style","name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"Nuxt PWA"},{"hid":"description","name":"description","content":"The official procurement mobile application"},{"hid":"theme-color","name":"theme-color","content":"#FE4C4C"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:title","property":"og:title","content":"Nuxt PWA"},{"hid":"og:site_name","property":"og:site_name","content":"Nuxt PWA"},{"hid":"og:description","property":"og:description","content":"The official procurement mobile application"},{"hid":"twitter:card","name":"twitter:card","content":"summary"}],"link":[{"rel":"icon","href":"/_nuxt/icons/64x64.1b89fa9c.png","key":"favicon"},{"rel":"apple-touch-icon","href":"/_nuxt/icons/512x512.maskable.1b89fa9c.png","sizes":"512x512","key":"favicon-apple"},{"rel":"manifest","href":"/manifest.json"}],"style":[],"script":[],"noscript":[],"title":"Nuxt PWA","htmlAttrs":{"lang":"en"}}

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"