import { datadogRum } from '@datadog/browser-rum';

export default ({$config: { ddApplicationId, ddApplicationToken, ddVersion, ddEnv } }) => {
    datadogRum.init({
        applicationId: ddApplicationId,
        clientToken: ddApplicationToken,
        site: 'datadoghq.com',
        service: 'shoppingnon',
        version: ddVersion,
        env: ddEnv,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
    });
    datadogRum.startSessionReplayRecording();
}