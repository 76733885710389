<script lang="ts" setup>
useHead({
    title: 'Shoppingnon',
    meta: [
        { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1' },
    ],
})
onMounted(() => {
    // @ts-ignore
    window.oncontextmenu = (event: PointerEvent) => {
        // If not started by a mouse, return false
        return event.pointerType === 'mouse'
    }
})
</script>

<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<style>
html, body , #__nuxt{
    height: 100vh;
    margin: 0;
    padding: 0;
    background: #151B17;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none !important;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    --app-max-width: 420px;
}

body {
    background-color: #FE4C4C !important;
}

* {
    touch-action: manipulation !important;
}

html.dark {
    color: white;
}

::-webkit-scrollbar {
    width: 0px;
}

.Vue-Toastification__toast--default {
    background-color: transparent;
    box-shadow: none;
    padding: 0
}

input:disabled, textarea:disabled, input:disabled::placeholder, textarea:disabled::placeholder {
  -webkit-text-fill-color: currentcolor; /* 1. sets text fill to current `color` for safari */
  opacity: 1; /* 2. correct opacity on iOS */
}
</style>
