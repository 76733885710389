import type { UseFetchOptions } from '#app'

export const route = (content: TemplateStringsArray, ...args: string[]) => {
    const config = useRuntimeConfig()
    const fullString = content.reduce((acc, cur, i) => {
        return acc + cur + (args[i] || '')
    }, '')
    const path = `${config.public.apiUrl}/${fullString}`.replace(/([^\:])\/{2,}/g, '$1/')
    return path
}

type APIFilterOperator = 'eq' | 'not_null'
export type APIFilter = Record<string, { [operator in APIFilterOperator]?: operator extends 'eq' ? string : string | boolean | undefined }>
export const createAPIFilter = (filters: APIFilter) => {
    const fields = Object.entries(filters).map(([field, filter]) => {
        const fieldFilters = Object.entries(filter).map(([operator, value]) => {
            return `${field} ${operator} ${typeof value === 'string' ? `${value}` : ''}`.trim()
        })
        return fieldFilters
    })
    return fields.flat()
}

export function api<T>(path: string, options?: UseFetchOptions<T>) {
    try {
        const user = useSupabaseUser()
        // const token = useSupabaseToken()
        const { appVersion, apiAuth } = useRuntimeConfig().public
        const requestOptions = {
            ...(options as any || {}),
            headers: {
                ...(options?.headers || {}),
                // 'Authorization': `Bearer ${token.value}`,
                'Api-Key': apiAuth,
                'X-App-Name': 'shoppingnon',
                'X-App-Version': appVersion,
                'X-App-User': user.value?.email ?? '',
            },
        }
        const url = route`${path}`
        if (__DEV__) {
            console.log(`\t↳ ${route`${path}`}`, requestOptions.params)
        }
        return $fetch<T>(url, requestOptions)
    }
    catch (e) {
        return null
    }
}

api.post = <T >(path: string, data: any, options?: UseFetchOptions<T>) => api<T>(path, {
    ...(options || {}),
    method: 'POST',
    body: data,
})

api.put = <T >(path: string, data: any, options?: UseFetchOptions<T>) => api<T>(path, {
    ...(options || {}),
    method: 'PUT',
    body: data,
})

api.patch = <T >(path: string, data: any, options?: UseFetchOptions<T>) => api<T>(path, {
    ...(options || {}),
    method: 'PATCH',
    body: data,
})

api.delete = <T >(path: string, options?: UseFetchOptions<T>) => api<T>(path, {
    ...(options || {}),
    method: 'DELETE',
})
